import * as React from 'react'
import { SWRConfig } from 'swr'
import { FrontasticState, getFrontasticState } from './FrontasticState'
import { fetchApiHub } from '../../lib/fetch-api-hub'
import DarkModeProvider from '../dark-mode'

const initialState: FrontasticState = {
  useCart: {} as any,
  useProduct: {} as any,
  useStripe: {} as any,
  usePdp: {} as any,
  useLoyalty: {} as any,
  useExpressCheckout: {} as any,
}

export const FrontasticContext = React.createContext<FrontasticState>(initialState)

export const FrontasticProvider = ({ children }) => {
  const state: FrontasticState = getFrontasticState()
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <DarkModeProvider>
        <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
      </DarkModeProvider>
    </SWRConfig>
  )
}

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider')
  }
}

export const useCart = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.useCart
}

export const useAccount = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return {}
}

export const useWishlist = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return {}
}

export const useStripe = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.useStripe
}

export const usePdp = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.usePdp
}

export const useProduct = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.useProduct
}
export const useLoyalty = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.useLoyalty
}

export const useExpressCheckout = () => {
  const context = React.useContext(FrontasticContext)
  checkContext(context)
  return context.useExpressCheckout
}
//TODO : Remove this comment later
