import { createContext, useContext, useState } from 'react'

const LocaleContext = createContext(undefined)
export const LocaleProvider = ({ children }) => {
  const [country, setCountry] = useState('US')
  const [currency, setCurrency] = useState('USD')
  const [countryName, setCountryName] = useState('United States')
  const [region, setRegion] = useState('United States')

  return (
    <LocaleContext.Provider
      value={{
        country,
        currency,
        countryName,
        region,
        setCountry,
        setCurrency,
        setCountryName,
        setRegion,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => {
  const useLocaleContext = useContext(LocaleContext)

  if (!useLocaleContext) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }

  return useLocaleContext
}
