import { useState } from 'react'
import { UseExpressCheckoutReturn } from './types'

const useExpressCheckout = (): UseExpressCheckoutReturn => {
  const [paypalAlertMessage, setPaypalAlertMessage] = useState<string>('')
  return {
    paypalAlertMessage,
    setPaypalAlertMessage,
  }
}

export default useExpressCheckout
//TODO : Remove this comment later
