import { UseStripe } from '@Types/payment/stripe'
import { getStore, getStoreByKey } from 'frontastic/actions/channel'
import useExpressCheckout from 'frontastic/actions/expressCheckout'
import { UseExpressCheckoutReturn } from 'frontastic/actions/expressCheckout/types'
import { UseLoyalty } from './UseLoyalty'
import { UsePdp } from './UsePdp'
import { UseProduct } from './UseProduct'
import { getLoyaltyTier } from '../../actions/loyalty'
import { getProductsByIds, getAllHemmingCharges } from '../../actions/product'
import useCart from '../../hooks/useCart'
import { UseCartReturn } from '../../hooks/useCart/types'

export interface FrontasticState {
  useCart: UseCartReturn
  useProduct: UseProduct
  useStripe?: UseStripe
  usePdp?: UsePdp
  useLoyalty: UseLoyalty
  useExpressCheckout: UseExpressCheckoutReturn
}

export const getFrontasticState = (): FrontasticState => {
  return {
    /**
     * Due to the structure that frontastic has been built, we need to use the useCart hook here, ignoring the hook rules
     * That will allow us to have more control over the functionalities
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCart: useCart(),

    useProduct: {
      getProductsByIds,
      getAllHemmingCharges,
    },
    usePdp: {
      getStore,
      getStoreByKey,
    },
    useLoyalty: {
      getLoyaltyTier,
    },
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExpressCheckout: useExpressCheckout(),
  }
}
