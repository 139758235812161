export const PAYMENT_METHOD_STRIPE = 'stripe'
export const PAYMENT_METHOD_CASH = 'cash'
export const PAYMENT_METHOD_CREDITCART = 'creditcard'
export const PAYMENT_METHOD_CARDPAYMENT = 'CARDPAYMENT'
export const PAYMENT_METHOD_PAYPAL = 'paypal'
export const PAYMENT_METHOD_APPLEPAY = 'applepay'
export const PAYMENT_METHOD_AFTERPAY = 'afterpay'
export const PAYMENT_METHOD_GCRC = 'gcrc'

export const COUNTRIES = [
  {
    name: 'Canada',
    code: 'CA',
    intlId: 'text.canada',
  },
  {
    name: 'United States',
    code: 'US',
    intlId: 'text.unitedStates',
  },
]

export const STATES = [
  {
    name: 'Alabama',
    code: 'AL',
    intlId: 'text.alabama',
  },
  {
    name: 'Alaska',
    code: 'AK',
    intlId: 'text.alaska',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    intlId: 'text.americanSamoa',
  },
  {
    name: 'Arizona',
    code: 'AZ',
    intlId: 'text.arizona',
  },
  {
    name: 'Arkansas',
    code: 'AR',
    intlId: 'text.arkansas',
  },
  {
    name: 'California',
    code: 'CA',
    intlId: 'text.california',
  },
  {
    name: 'Colorado',
    code: 'CO',
    intlId: 'text.colorado',
  },
  {
    name: 'Connecticut',
    code: 'CT',
    intlId: 'text.connecticut',
  },
  {
    name: 'D.C',
    code: 'DC',
    intlId: 'text.DC',
  },
  {
    name: 'Delaware',
    code: 'DE',
    intlId: 'text.delaware',
  },
  {
    name: 'Federated States of Micronesia',
    code: 'FM',
    intlId: 'text.federatedStatesOfMicronesia',
  },
  {
    name: 'Florida',
    code: 'FL',
    intlId: 'text.florida',
  },
  {
    name: 'Georgia',
    code: 'GA',
    intlId: 'text.georgia',
  },
  {
    name: 'Guam',
    code: 'GU',
    intlId: 'text.guam',
  },
  {
    name: 'Hawaii',
    code: 'HI',
    intlId: 'text.hawaii',
  },
  {
    name: 'Idaho',
    code: 'ID',
    intlId: 'text.idaho',
  },
  {
    name: 'Illinois',
    code: 'IL',
    intlId: 'text.illinois',
  },
  {
    name: 'Indiana',
    code: 'IN',
    intlId: 'text.indiana',
  },
  {
    name: 'Iowa',
    code: 'IA',
    intlId: 'text.iowa',
  },
  {
    name: 'Kansas',
    code: 'KS',
    intlId: 'text.kansas',
  },
  {
    name: 'Kentucky',
    code: 'KY',
    intlId: 'text.kentucky',
  },
  {
    name: 'Louisiana',
    code: 'LA',
    intlId: 'text.louisiana',
  },
  {
    name: 'Maine',
    code: 'ME',
    intlId: 'text.maine',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    intlId: 'text.marshallIslands',
  },
  {
    name: 'Maryland',
    code: 'MD',
    intlId: 'text.maryland',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
    intlId: 'text.massachusetts',
  },
  {
    name: 'Michigan',
    code: 'MI',
    intlId: 'text.michigan',
  },
  {
    name: 'Minnesota',
    code: 'MN',
    intlId: 'text.minnesota',
  },
  {
    name: 'Mississippi',
    code: 'MS',
    intlId: 'text.mississippi',
  },
  {
    name: 'Missouri',
    code: 'MO',
    intlId: 'text.missouri',
  },
  {
    name: 'Montana',
    code: 'MT',
    intlId: 'text.montana',
  },
  {
    name: 'Nebraska',
    code: 'NE',
    intlId: 'text.nebraska',
  },
  {
    name: 'Nevada',
    code: 'NV',
    intlId: 'text.nevada',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
    intlId: 'text.newHampshire',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
    intlId: 'text.newJersey',
  },
  {
    name: 'New Mexico',
    code: 'NM',
    intlId: 'text.newMexico',
  },
  {
    name: 'New York',
    code: 'NY',
    intlId: 'text.newYork',
  },
  {
    name: 'North Carolina',
    code: 'NC',
    intlId: 'text.northCarolina',
  },
  {
    name: 'North Dakota',
    code: 'ND',
    intlId: 'text.northDakota',
  },
  {
    name: 'North Mariana Island',
    code: 'MP',
    intlId: 'text.northMarianaIsland',
  },
  {
    name: 'Ohio',
    code: 'OH',
    intlId: 'text.ohio',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
    intlId: 'text.oklahoma',
  },
  {
    name: 'Oregon',
    code: 'OR',
    intlId: 'text.oregon',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
    intlId: 'text.pennsylvania',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    intlId: 'text.puertoRico',
  },
  {
    name: 'Republic of Palau',
    code: 'PW',
    intlId: 'text.republicOfPalau',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
    intlId: 'text.rhodeIsland',
  },
  {
    name: 'South Carolina',
    code: 'SC',
    intlId: 'text.southCarolina',
  },
  {
    name: 'South Dakota',
    code: 'SD',
    intlId: 'text.southDakota',
  },
  {
    name: 'Tennessee',
    code: 'TN',
    intlId: 'text.tennessee',
  },
  {
    name: 'Texas',
    code: 'TX',
    intlId: 'text.texas',
  },
  {
    name: 'U.S Minor Outlying Islands',
    code: 'UM',
    intlId: 'text.USMinorOutlyingIslands',
  },
  {
    name: 'Utah',
    code: 'UT',
    intlId: 'text.utah',
  },
  {
    name: 'Vermont',
    code: 'VT',
    intlId: 'text.vermont',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
    intlId: 'text.virginIslands',
  },
  {
    name: 'Virginia',
    code: 'VA',
    intlId: 'text.virginia',
  },
  {
    name: 'Washington',
    code: 'WA',
    intlId: 'text.washington',
  },
  {
    name: 'West Virginia',
    code: 'WV',
    intlId: 'text.westVirginia',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
    intlId: 'text.wisconsin',
  },
  {
    name: 'Wyoming',
    code: 'WY',
    intlId: 'text.wyoming',
  },
  {
    name: 'Armed Forces Americas AA',
    code: 'AA',
    intlId: 'text.AA',
  },
  {
    name: 'Armed Forces Europe AE',
    code: 'AE',
    intlId: 'text.AE',
  },
  {
    name: 'Armed Forces Pacific AP',
    code: 'AP',
    intlId: 'text.AP',
  },
]

export const US_ARMY_STATES = ['AA', 'AE', 'AP']

export const US_ARMY_STATES_WITH_NAME = [
  {
    name: 'Armed Forces Americas AA',
    code: 'AA',
    intlId: 'text.AA',
  },
  {
    name: 'Armed Forces Europe AE',
    code: 'AE',
    intlId: 'text.AE',
  },
  {
    name: 'Armed Forces Pacific AP',
    code: 'AP',
    intlId: 'text.AP',
  },
]
