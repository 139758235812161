import { mutate } from 'swr'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export interface CheckoutAddressType {
  id: string
  additional_address_info: string
  additional_street_info: string
  first_name: string
  last_name: string
  phone_number: string
  street_name: string
  street_number: string
  city: string
  state: string
  postcode: string
  country: string
}

export interface ExperianAddressType {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  country: string
  locality: string
  postal_code: string
  postal_code_extended?: string
  region: string
  confidence?: string
}
export const fetchAdqAddressSuggestion = async (address: string, location?: string) => {
  const res = await fetchApiHub(
    '/action/addressSuggestion/fetchAdqSuggestions',
    { method: 'POST' },
    { address, location },
  )
  await mutate('/action/addressSuggestion/fetchAdqSuggestions', res)
  return res
}
export const fetchExperianSuggestions = async (address: string) => {
  const res = await fetchApiHub('/action/addressSuggestion/fetchSuggestions', { method: 'POST' }, { address })
  await mutate('/action/addressSuggestion/fetchSuggestions', res)
  return res
}
export const formatAdqAddress = async (globalAddressKey: string) => {
  const res = await fetchApiHub('/action/addressSuggestion/formatAdqAddress', { method: 'POST' }, { globalAddressKey })
  await mutate('/action/addressSuggestion/formatAdqAddress', res)
  return res
}

export const formatExperianAddress = async (globalAddressKey: string) => {
  const res = await fetchApiHub('/action/addressSuggestion/formatAddress', { method: 'POST' }, { globalAddressKey })
  await mutate('/action/addressSuggestion/formatAddress', res)
  return res
}

export const fetchFormattedExperianClosestSuggestion = async (checkoutAddress: CheckoutAddressType) => {
  const res = await fetchApiHub(
    '/action/addressSuggestion/fetchClosestSuggestion',
    { method: 'POST' },
    { checkoutAddress },
  )
  await mutate('/action/addressSuggestion/fetchClosestSuggestion', res)
  return res
}

export const validateAndFetchExperianClosestSuggestion = async (checkoutAddress: CheckoutAddressType) => {
  const res = await fetchApiHub('/action/addressSuggestion/validateAddress', { method: 'POST' }, { checkoutAddress })
  await mutate('/action/addressSuggestion/validateAndFetchExperianSuggestion', res)
  return res
}

export const fetchOfferResponseApi = async (integrationId: any) => {
  const res = await fetchApiHub(
    '/action/marigold/fetchOfferResponse',
    { method: 'POST' },
    { integrationId: integrationId },
  )
  await mutate('/action/marigold/fetchOfferResponse', res)
  return res
}
export const fetchRewardCertificates = async (integrationId: any) => {
  const res = await fetchApiHub(
    '/action/marigold/fetchRewardCertificate',
    { method: 'POST' },
    { integrationId: integrationId },
  )
  return res
}
//TODO : Remove this comment later
