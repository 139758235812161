import { useCallback, useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Cart } from '@Types/cart/Cart'
import { LineItem } from '@Types/cart/LineItem'
import { Order } from '@Types/cart/Order'
import { useLocalStorage } from 'composable/components/hooks/useLocalStorage'
import { storeDataDefaultValue } from 'composable/components/mystore/constants'
import useI18n from 'helpers/hooks/useI18n'
import mapCosts from 'helpers/utils/mapCosts'
import { sdk } from 'sdk'
import { Discount } from 'shared/types/cart/Discount'
import useSWR from 'swr'
import { revalidateOptions } from 'frontastic'
import { CartDetails, LineItemPayload, UseCartReturn } from './types'
import { setResToMutate, normalizeCartData } from './utils'
import { DONATIONS, DONATIONS_AND_GC, raiseToast } from '../../../composable'
import { SHIPPING_METHODS } from '../../../composable/components/general/constants'
import { useFormat } from '../../../helpers/hooks/useFormat'
import {
  confirmCart,
  checkout,
  addPaymentByInvoice,
  setHandlingFees,
  updateCartItems,
  setInstoreCart,
  getCart,
  removeItemFromCart,
  getOrder,
  addItemToCart,
  updateCartCT,
  setOrUpdateLineItemCustomType,
  setShippingMethodCT,
} from '../../actions/cart'
import { myBag } from '../../contexts/atgCartContext/helpers/constants'
import { UseDisclosureReturn } from '@chakra-ui/react'

const useCart = (): UseCartReturn => {
  const [selectedStoreData, setSelectedStoreData] = useLocalStorage('selectedStoreData', storeDataDefaultValue)
  const [isCartPageLoading, setCartPageLoading] = useState<boolean>(false)
  const extensions = sdk.composableCommerce

  const { currency } = useI18n()
  const intl = useFormat({ name: 'common' })
  const toast = useToast()

  const { data: cart, mutate } = useSWR(
    '/action/cart/getCart',
    async () => normalizeCartData(await getCart(selectedStoreData?.key)),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )

  const shippingMethodsResults = useSWR(
    '/action/cart/getShippingMethods',
    extensions.cart.getShippingMethods,
    revalidateOptions,
  )

  /**
   * @todo
   * `const result` has the following structure in different moments:
   *
   * {isError: false, data: {cartId: 'e839d02d...', …}}
   * AND
   * {cartId: 'e839d02d...',  …}
   *
   * Needs to investigate why the data is not being returned as expected
   */

  const shippingMethods = shippingMethodsResults.data?.isError
    ? { data: [] }
    : (shippingMethodsResults.data as any)?.data || { data: [] }

  const totalItems = useMemo(() => {
    return cart?.lineItems?.reduce((acc, curr) => acc + (curr?.count || 0), 0) || 0
  }, [cart])

  const isEmpty = useMemo(() => !cart?.lineItems?.length, [cart])

  const isShippingAccurate = useMemo(() => !!cart?.shippingInfo, [cart])

  const hasOutOfStockItems = !!cart?.lineItems?.some((lineItem) => !lineItem.variant?.isOnStock)

  const transaction = useMemo(() => {
    if (cart !== undefined) {
      return mapCosts({ reference: 'cart', cart, currency })
    }
  }, [cart, currency])

  const addItem = useCallback(
    async (variantSku: string, quantity: number, payload?: LineItemPayload, cartDrawer?: UseDisclosureReturn) => {
      const {
        shippingMethod,
        pickUpStoreId,
        pickUpStoreName,
        quantity: payloadQuantity,
        customizationDataCuff,
        customizationDataInseam,
        productTitle,
        giftCardAmount,
        isPromoExcluded,
      } = payload

      const requestPayload = {
        variant: {
          sku: variantSku,
          count: quantity,
        },
        // custom fields - no relation with CT data types - will be handled in the BE
        customFields: {
          shippingMethod: shippingMethod || SHIPPING_METHODS.SHIPPING,
          ...(pickUpStoreId && { pickUpStoreId, pickUpStoreName }),
          ...(payloadQuantity && { quantity: payloadQuantity }),
          ...(customizationDataCuff && { customizationDataCuff }),
          ...(customizationDataInseam && { customizationDataInseam }),
          ...(giftCardAmount && { giftCardAmount }),
          ...(isPromoExcluded && { isPromoExcluded }),
        },
      }

      try {
        const res = await addItemToCart(requestPayload)
        setResToMutate(res, false, mutate)

        if (res.isError) {
          raiseToast(
            myBag,
            intl.formatMessage({
              id: 'cart.item.add.error',
              values: { name: productTitle },
            }),
            res.isError ? 'error' : 'success',
            toast,
          )
        } else {
          cartDrawer?.onOpen()
        }
      } catch (ex) {
        raiseToast(
          myBag,
          intl.formatMessage({
            id: 'cart.item.add.error',
            values: { name: productTitle },
          }),
          'error',
          toast,
        )
      }
    },
    [],
  )

  const orderCart = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'cart/checkout' })

    const cart = await getCart(selectedStoreData?.key)
    setResToMutate(cart, false, mutate)

    return (res.isError ? {} : (res as any).data) as Order
  }, [])

  const orderHistory = useCallback(async () => {
    const extensions = sdk.composableCommerce
    const res = await extensions.cart.getOrderHistory()
    return res.isError ? ([] as Order[]) : ((res as any).data as Order[])
  }, [])

  const getProjectSettings = useCallback(async () => {
    const extensions = sdk.composableCommerce
    const res = await extensions.project.getSettings()
    return res.isError ? {} : (res as any).data
  }, [])

  const removeItem = useCallback(async (lineItemId: string, productName?: string) => {
    try {
      const res = await removeItemFromCart(lineItemId)
      setResToMutate(res, false, mutate)
    } catch (ex) {
      raiseToast(
        myBag,
        intl.formatMessage({ id: 'cart.item.remove.error', values: { name: productName || lineItemId } }),
        'error',
        toast,
      )
    }
  }, [])

  const updateItem = useCallback(
    async (lineItemId: string, newQuantity: number, payload?: LineItemPayload, cartDrawer?: UseDisclosureReturn) => {
      const {
        shippingMethod,
        pickUpStoreId,
        quantity: payloadQuantity,
        customizationDataCuff,
        customizationDataInseam,
        customizationCharge,
        customizationChargeTaxCode,
        productTitle,
      } = payload || {}

      const extensions = sdk.composableCommerce
      const res = await extensions.cart.getCart()
      const cart = (res as any).data
      const fullLineItemObj = cart?.lineItems?.find((item: LineItem) => item.lineItemId === lineItemId)
      const lineItemshippingMethod = fullLineItemObj?.custom.fields?.shippingMethod || SHIPPING_METHODS.SHIPPING
      const pickUpStoreWithId = fullLineItemObj?.shippingDetails?.targets?.[0]?.addressKey

      const requestPayload = {
        lineItem: {
          id: lineItemId,
          count: newQuantity,
        },
        shippingMethod: shippingMethod || lineItemshippingMethod,
        ...(pickUpStoreWithId && { pickUpStoreId: pickUpStoreWithId }),
        ...(payloadQuantity && { quantity: payloadQuantity }),
        ...(customizationDataCuff && { customizationDataCuff }),
        ...(customizationDataInseam && { customizationDataInseam }),
        ...(customizationCharge && { customizationCharge }),
        ...(customizationChargeTaxCode && { customizationChargeTaxCode }),
      }

      try {
        const res = await extensions.cart.updateItem(requestPayload)
        setResToMutate(res, false, mutate)

        if (res.isError) {
          raiseToast(
            myBag,
            intl.formatMessage({
              id: 'cart.item.qty.update.error',
              values: { name: productTitle },
            }),
            'error',
            toast,
          )
        } else {
          cartDrawer?.onOpen()
        }
      } catch (ex) {
        raiseToast(
          myBag,
          intl.formatMessage({
            id: 'cart.item.qty.update.error',
            values: { name: productTitle },
          }),
          'error',
          toast,
        )
      }
    },
    [],
  )

  const updateCart = useCallback(async (payload: CartDetails): Promise<Cart> => {
    try {
      const res = await updateCartCT(payload)

      if (res) {
        setResToMutate(res, false, mutate)
        return res
      }
    } catch (ex) {
      raiseToast(myBag, intl.formatMessage({ id: 'cart.update.error' }), 'error', toast)
    }
  }, [])

  const setShippingMethod = useCallback(async (shippingMethodId: string) => {
    try {
      const res = await setShippingMethodCT(shippingMethodId)
      setResToMutate(res, false, mutate)
      return res
    } catch (ex) {
      raiseToast(myBag, intl.formatMessage({ id: 'cart.setShippingMethod.error' }), 'error', toast)
    }
  }, [])

  const redeemDiscountCode = useCallback(async (code: string) => {
    const extensions = sdk.composableCommerce

    const payload = {
      code: code,
    }
    const res = await extensions.cart.redeemDiscountCode(payload)

    if (!res.isError && ((res as any).data as Cart).cartId) {
      setResToMutate(res, false, mutate)
    }

    return res
  }, [])

  const removeDiscountCode = useCallback(async (discount: Discount) => {
    const extensions = sdk.composableCommerce

    const res = await extensions.cart.removeDiscountCode({ discountId: discount.discountId as string })

    if (!res.isError && ((res as any).data as Cart).cartId) {
      setResToMutate(res, false, mutate)
    }

    return res
  }, [])

  const recalculateCart = async () => {
    const res = await sdk.callAction({ actionName: 'cart/recalculateCart', payload: {} })
    // Manually update the SWR cache with the new cart
    setResToMutate(res, false, mutate)
  }

  const getCartDiscountByKey = async (key: string) => {
    const payload = {
      key,
    }

    const res = await sdk.callAction({ actionName: 'cart/getCartDiscountByKey', payload })
    setResToMutate(res, false, mutate)
    return res
  }

  const setLineItemCustomType = async (lineItemId: string, customData: any) => {
    try {
      const res = await setOrUpdateLineItemCustomType(lineItemId, customData)
      setResToMutate(res, false, mutate)
    } catch (ex) {
      raiseToast(
        myBag,
        intl.formatMessage({ id: 'cart.item.remove.error', values: { name: lineItemId } }),
        'error',
        toast,
      )
    }
  }

  const pickUpStoresBOPIS = useMemo(() => {
    if (!cart?.lineItems) return []

    const isBOPISShippingMethod = cart.lineItems.every((item) => item.custom?.fields?.BOPISShippingMethod === '02')

    if (!isBOPISShippingMethod) return []

    return cart.lineItems
      .map((item) => {
        const storeId = item.custom?.fields?.pickUpStoreId
        const storeName = item.custom?.fields?.pickUpStoreName
        if (storeId && storeName) {
          return { storeId, storeName }
        }
        return null
      })
      .filter(Boolean)
  }, [cart])

  const isAllGiftCardOrDonation = useMemo(() => {
    if (!cart?.lineItems) return false
    return cart.lineItems.every((item) => DONATIONS_AND_GC.includes(String(item?.variant?.key)))
  }, [cart?.lineItems])

  const isAllDonation = useMemo(() => {
    if (!cart?.lineItems) return false
    return cart.lineItems.every((item) => DONATIONS.includes(String(item?.variant?.key)))
  }, [cart?.lineItems])

  return {
    cart,
    pickUpStoresBOPIS,
    isAllGiftCardOrDonation,
    isAllDonation,
    totalItems,
    isEmpty,
    isShippingAccurate,
    hasOutOfStockItems,
    transaction,
    addItem,
    updateCart,
    setShippingMethod,
    removeItem,
    updateItem,
    shippingMethods,
    orderCart,
    getOrder,
    orderHistory,
    getProjectSettings,
    redeemDiscountCode,
    removeDiscountCode,
    recalculateCart,
    confirmCart,
    checkout,
    addPaymentByInvoice,
    setHandlingFees,
    updateCartItems,
    setInstoreCart,
    getCartDiscountByKey,
    setLineItemCustomType,
    isCartPageLoading,
    setCartPageLoading,
  }
}

export default useCart
