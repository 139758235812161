import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { sdk } from 'sdk'

const useFetchRewardsProfile = ({ integrationId, options }) => {
  return useQuery({
    queryFn: async () => {
      const res = await sdk.callAction({
        actionName: 'marigold/fetchProfile',
        payload: {
          integrationId,
        },
      })
      return (res as any).data
    },
    queryKey: ['use-fetch-rewards-profile-hook'],
    ...options,
  })
}

const useFetchRewardsSummary = ({ integrationId, options }) => {
  return useQuery({
    queryFn: async () => {
      const res = await sdk.callAction({
        actionName: 'marigold/fetchSummary',
        payload: { integrationId },
      })
      return (res as any).data
    },
    queryKey: ['use-fetch-rewards-summary-hook'],
    ...options,
  })
}

const useFetchRewardsActivities = ({ integrationId, options }) => {
  return useQuery({
    queryFn: async () => {
      const res = await sdk.callAction({
        actionName: 'marigold/fetchActivities',
        payload: { integrationId },
      })
      return (res as any).data
    },
    queryKey: ['use-fetch-rewards-activities-hook'],
    ...options,
  })
}

const useFetchRewardsOfferResponse = ({ integrationId, options }) => {
  return useQuery({
    queryFn: async () => {
      const res = await sdk.callAction({
        actionName: 'marigold/fetchOfferResponse',
        payload: { integrationId },
      })
      return (res as any).data
    },
    queryKey: ['use-fetch-rewards-offer-response-hook'],
    ...options,
  })
}

export type UseRewardsType = {
  summary?: any
  profile?: any
  tierChangePrior?: string
  activities?: any
  offerResponse: any
  isSubscribed?: boolean
  optInDate?: string
  isSummaryLoading?: boolean
  isProfileLoading?: boolean
  isActivitiesLoading?: boolean
  isOfferResponseLoading?: boolean
  setHookReadiness?: (value: boolean) => void
  setIntegrationId?: (value: string) => void
  setIsSubscribed?: (value: boolean) => void
  setOptInDate?: (value: string) => void
}

export const useRewards = (): UseRewardsType => {
  const [summary, setSummary] = useState(null)
  const [profile, setProfile] = useState(null)
  const [activities, setActivities] = useState(null)
  const [offerResponse, setOfferResponse] = useState(null)
  const [hookIsReady, setHookReadiness] = useState<boolean>(false)
  const [integrationId, setIntegrationId] = useState<string>()
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [optInDate, setOptInDate] = useState<string>()
  const [tierChangePrior, setTierChangePrior] = useState<string | null>(null)

  const { data: summaryData, isLoading: isSummaryLoading } = useFetchRewardsSummary({
    integrationId,
    options: { enabled: hookIsReady },
  })
  const { data: profileData, isLoading: isProfileLoading } = useFetchRewardsProfile({
    integrationId,
    options: { enabled: hookIsReady },
  })
  const { data: activitiesData, isLoading: isActivitiesLoading } = useFetchRewardsActivities({
    integrationId,
    options: { enabled: hookIsReady },
  })
  const { data: offerResponseData, isLoading: isOfferResponseLoading } = useFetchRewardsOfferResponse({
    integrationId,
    options: { enabled: hookIsReady },
  })

  useEffect(() => {
    if (!!summaryData) {
      setSummary(summaryData)
    }
  }, [summaryData])

  useEffect(() => {
    if (!!profileData) {
      setProfile(profileData)
    }
  }, [profileData])

  useEffect(() => {
    if (profile?.tier_upgrade_date) {
      const adjustedDate = calculateDateToOneDayPriorAdjusted(profile.tier_upgrade_date)
      const formattedDate = formatToMDDYY(adjustedDate)
      setTierChangePrior(formattedDate)
    }
  }, [profile])

  useEffect(() => {
    if (!!activitiesData) {
      setActivities(activitiesData)
    }
  }, [activitiesData])

  useEffect(() => {
    if (!!offerResponseData) {
      setOfferResponse(offerResponseData)
    }
  }, [offerResponseData])

  return {
    summary,
    profile,
    tierChangePrior,
    activities,
    offerResponse,
    isSubscribed,
    optInDate,
    isSummaryLoading,
    isProfileLoading,
    isActivitiesLoading,
    isOfferResponseLoading,
    setHookReadiness,
    setIntegrationId,
    setIsSubscribed,
    setOptInDate,
  }
}

const formatToMDDYY = (isoDate: string): string => {
  const [year, month, day] = isoDate.split('-')
  const shortYear = year.slice(-2)
  return `${parseInt(month, 10)}/${parseInt(day, 10)}/${shortYear}`
}

const calculateDateToOneDayPriorAdjusted = (tierUpgradeDate: string): string => {
  const [year, month, day] = tierUpgradeDate.split('-').map(Number)
  let adjustedDay = day - 1
  let adjustedMonth = month
  let adjustedYear = year

  if (adjustedDay === 0) {
    adjustedMonth -= 1
    if (adjustedMonth === 0) {
      adjustedMonth = 12
      adjustedYear -= 1
    }
    const daysInPrevMonth = new Date(adjustedYear, adjustedMonth, 0).getDate()
    adjustedDay = daysInPrevMonth
  }

  return `${adjustedYear}-${String(adjustedMonth).padStart(2, '0')}-${String(adjustedDay).padStart(2, '0')}`
}
