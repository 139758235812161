import { ReactNode } from 'react'
import { Text, HStack, StackProps, Tooltip, Flex, TextProps } from '@chakra-ui/react'
import { PiInfoFill } from 'react-icons/pi'
import { useToolTip } from 'composable/helpers/hooks/useTooltip'

type SummaryLineItemProps = StackProps & {
  label: string
  value?: string
  textStyle?: TextProps['textStyle'] | string
  children?: ReactNode
  isDiscount?: boolean
  doNotShowNegativeSign?: boolean
  showToolTip?: boolean
  toolTipMessage?: string
}

export const SummaryLineItem = ({
  label,
  value,
  textStyle,
  children,
  isDiscount,
  showToolTip,
  toolTipMessage,
  doNotShowNegativeSign = false,
  ...props
}: SummaryLineItemProps) => {
  const { showTooltip, handleTouchStart, handleTouchEnd, handleMouseEnter, handleMouseLeave } = useToolTip()

  return (
    <HStack tabIndex={0} justify="space-between" mt={1} {...props}>
      <Flex align="center">
        <Text textStyle={textStyle} textColor={'text.primary'}>
          {label}
        </Text>
        {showToolTip && toolTipMessage && (
          <Tooltip
            label={toolTipMessage}
            placement="top"
            rounded="md"
            maxW={{ base: '90vw', sm: '330px' }}
            ml={{ base: 2, sm: 0 }}
            hasArrow
            isOpen={showTooltip}
          >
            <Flex
              as="span"
              align="center"
              justify="center"
              ml={2}
              cursor="pointer"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <PiInfoFill size="16px" />
            </Flex>
          </Tooltip>
        )}
      </Flex>

      {value ? (
        <Text textStyle={textStyle} color={isDiscount ? 'danger.600' : 'text.primary'} textAlign="right">
          {isDiscount && !doNotShowNegativeSign && '-'}
          {value}
        </Text>
      ) : (
        children
      )}
    </HStack>
  )
}
