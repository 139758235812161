import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'
import {
  AUTH_ZERO_DOMAIN,
  AUTH_ZERO_CLIENT_ID,
  AUTH_ZERO_REQUEST_SCOPE,
  AUTH_ZERO_MULTIPLE_RUNS_SESSION_RETRIES,
  AUTH_ZERO_MULTIPLE_RUNS_SESSION_INTERVAL,
} from './constants'
import { NextRouter } from 'next/router'
import { Auth0UpdateUserPayload, DecodedToken, NewUserPayloadType } from './types'

// =========== Auth Helpers =========== //
export const redirectToAuth0Login = (userPreviousRoute: string) => {
  const redirectUri = `${window.location.origin}/api/auth/login?redirectBack=${userPreviousRoute}`

  const loginUrl = `${AUTH_ZERO_DOMAIN}/authorize?response_type=code&client_id=${AUTH_ZERO_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${AUTH_ZERO_REQUEST_SCOPE}`
  window.location.href = loginUrl
}

export const redirectToAuth0Register = () => {
  const redirectUri = `${window.location.origin}/api/auth/login?lastAction=register` // Must match one value in the Allowed Callback URLs in Auth0 config

  const loginUrl = `${AUTH_ZERO_DOMAIN}/authorize?response_type=code&client_id=${AUTH_ZERO_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${AUTH_ZERO_REQUEST_SCOPE}&screen_hint=signup`
  window.location.href = loginUrl
}

export const logInUser = async (code: string, localRedirectUri?: string) => {
  const res = await fetchApiHub(
    '/action/account/getAccessTokenFromAuthZero',
    {
      method: 'POST',
    },
    { code, localRedirectUri, scope: AUTH_ZERO_REQUEST_SCOPE },
  )

  return res
}

export const clearAccountInSession = async () => {
  const res = await fetchApiHub('/action/account/clearAccountInSession', {
    method: 'POST',
  })
  return res
}

export const loginCT = async (userPayload: NewUserPayloadType, key: string, accessToken: string) => {
  const res = await fetchApiHub(
    '/action/account/loginCT',
    {
      method: 'POST',
    },
    { userPayload, key, accessToken },
  )

  return res
}

export const logOutUser = async () => {
  const returnToBase = window.location.origin // Your app's base URL
  window.location.href = `/api/auth/logout?returnTo=${window.location.origin}`
}

export const requestPasswordChangeAuthZero = async (email: string, accessToken: string) => {
  const res = await fetchApiHub(
    '/action/account/requestPasswordChangeAuthZero',
    {
      method: 'POST',
    },
    { email, accessToken },
  )

  return res
}

export const authZeroUpdate = async (payload: Auth0UpdateUserPayload) => {
  const res = await fetchApiHub(
    '/action/account/authZeroUpdate',
    {
      method: 'POST',
    },
    { payload },
  )

  return res
}

// =========== Token Helpers =========== //
export const decodeJWT = (token: string) => {
  // Split the token into 3 parts (header, payload, signature)
  const [header, payload, signature] = token.split('.')

  // Decode the base64url encoded string to base64
  const base64UrlToBase64 = (base64Url) => {
    return base64Url
      .replace(/-/g, '+') // Replace '-' with '+'
      .replace(/_/g, '/') // Replace '_' with '/'
  }

  // Decode the payload and header
  const decodedHeader = JSON.parse(atob(base64UrlToBase64(header)))
  const decodedPayload = JSON.parse(atob(base64UrlToBase64(payload)))

  return { decodedHeader, decodedPayload }
}

export const checkTokenExpiration = (expiration: number) => {
  const isExpired = expiration ? Date.now() >= expiration * 1000 : false

  return isExpired
}

export const getTokenSub = (decodedToken: DecodedToken) => {
  const tokenSub = decodedToken.decodedPayload.sub
  const userIdAuthZero = tokenSub.split('|')[1]

  return userIdAuthZero
}

export const getTokenAuthProvider = (decodedToken: DecodedToken) => {
  const tokenSub = decodedToken.decodedPayload.sub
  const userIdAuthZero = tokenSub.split('|')[0]

  return userIdAuthZero
}

export const getNewUserPayload = (decodedToken: DecodedToken) => {
  const userPayload = {
    email: decodedToken.decodedPayload.email,
    firstName: decodedToken.decodedPayload.given_name || '',
    lastName: decodedToken.decodedPayload.family_name || '',
  }

  return userPayload as NewUserPayloadType
}

// =========== Router Helpers =========== //
export const handleShallowRouteReplace = (
  router: NextRouter,
  queryWithoutCode: { [key: string]: string | string[] },
) => {
  router.replace(
    {
      pathname: router.pathname,
      query: queryWithoutCode,
    },
    undefined,
    { shallow: true }, // Using shallow routing to avoid a full page reload
  )
}

export const setAccountInSession = async (user) => {
  const res = await fetchApiHub(
    '/action/account/setAccountInSession',
    {
      method: 'POST',
    },
    {
      user,
    },
  )
  return res
}

export const clearSessionMultipleRuns = async () => {
  let count = 0
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      await clearAccountInSession()
      count++
      if (count >= AUTH_ZERO_MULTIPLE_RUNS_SESSION_RETRIES) {
        clearInterval(interval) // Stop after the defined number of retries
        resolve(true) // Resolve the promise when done
      }
    }, AUTH_ZERO_MULTIPLE_RUNS_SESSION_INTERVAL)
  })
}

export const setSessionMultipleRuns = async (user) => {
  let count = 0
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      await setAccountInSession(user)
      count++
      if (count >= AUTH_ZERO_MULTIPLE_RUNS_SESSION_RETRIES) {
        clearInterval(interval) // Stop after the defined number of retries
        resolve(true) // Resolve the promise when done
      }
    }, AUTH_ZERO_MULTIPLE_RUNS_SESSION_INTERVAL)
  })
}

export const isAuthQueryPresent = (query) => {
  return query?.loggedOut === 'true' || query?.tokenExpired === 'true' || query?.authError === 'true'
}
