import { MarigoldApiOfferResponses } from '@Types/marigold-api/MarigoldApiOfferResponses'
import { isUsableCertificate } from 'composable/components/checkout/checkout-provider/checkout-utils'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAccountCT } from '../accountCTContext'
import { fetchRewardCertificates } from 'frontastic/actions/experian'

const RewardCertificateContext = createContext(undefined)

const RewardCertificateProvider = ({ children }) => {
  const { account } = useAccountCT()
  const [activeRewardCertificates, setActiveRewardCertificates] = useState<
    MarigoldApiOfferResponses['responses'] | null
  >(null)
  const getRewardCertificates = async () => {
    setActiveRewardCertificates([])
    if (!account?.externalId) {
      return
    }
    if (account?.externalId) {
      let data: MarigoldApiOfferResponses = await fetchRewardCertificates(account?.externalId)
      let responses = data?.responses || []
      responses = responses?.filter((item) => {
        return isUsableCertificate(
          item?.usage_end_date || ('' as any),
          item?.offer?.isInactive,
          item?.offer?.isAlreadyUsed,
        )
      })
      setActiveRewardCertificates(responses || [])
    }
  }

  useEffect(() => {
    getRewardCertificates()
  }, [account?.externalId])
  return (
    <RewardCertificateContext.Provider value={{ activeRewardCertificates, getRewardCertificates }}>
      {children}
    </RewardCertificateContext.Provider>
  )
}

export const useRewardCertificate = () => {
  const useRewardContext = useContext(RewardCertificateContext)
  if (!useRewardContext) {
    throw new Error('useRewardCertificate must be used within a RewardCertificateProvider')
  }

  return useRewardContext
}
export default RewardCertificateProvider
